body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #ffffff;
}

.app {
  height: 100vh;
}

.navbar {
  background-color: #ffffff;
  padding: 1rem;
  border-bottom: 1px solid #e7ecef;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap; /* Додано flex-wrap для адаптивності */
}

.nav-left, .nav-right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.brand {
  background-color: #76c7c0;
  padding: 10px 20px;
  border-radius: 10px;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.nav-link {
  background-color: #ffffff;
  padding: 10px 20px;
  border-radius: 10px;
  border-color: #000000;
  color: #000000;
  text-decoration: none;
  font-weight: bold;
}

.nav-link:hover {
  background-color: #76c7c0;
  border-color: #76c7c0;
}

.study-link {
  color: #5cb3c3;
  text-decoration: none;
}

.study-link:hover {
  color: #000000;
}

/* Додано медіа-запит для адаптивності */
@media (max-width: 768px) {
  .container {
      flex-direction: column;
      align-items: flex-start;
  }
  
  .nav-right {
      margin-top: 10px;
  }
}




.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
}

.card {
  background-color: #c3e8eb;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 20px;
  width: calc(35% - 20px); /* Дві картки в одному рядку з відступами */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.card h2 {
  margin-top: 0;
  color: #e74c3c; /* Червоний колір заголовка */
}

.card img {
  max-height: 500px;
  max-width: 500px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 10px 0;
}

.card h4 {
  margin: 10px 0 5px;
  color: #333;
}

.card p {
  margin-bottom: 1rem;
  color: #666;
}

.small-card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 20px;
  gap: 20px;
}

.small-card {
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 20px;
  width: calc(30.333% - 40px); /* Три картки в одному рядку з відступами */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.small-card h2 {
  margin-top: 0;
  color: #e74c3c; /* Червоний колір заголовка */
}

.small-card img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin: 10px 0;
}

.small-card h4 {
  margin: 10px 0 5px;
  color: #333;
}

.small-card p {
  margin-bottom: 1rem;
  color: #666;
}

.button-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.button-container button {
  background-color: #007bff;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.button-container button:hover {
  background-color: #0056b3;
}

/* Додаткові стилі для кращого вигляду на мобільних пристроях */
@media (max-width: 768px) {
  .card {
      width: calc(100% - 20px); /* Картки займають всю ширину на менших екранах */
  }

  .small-card {
      width: calc(100% - 20px); /* Картки займають всю ширину на менших екранах */
  }
}


.footer {
  bottom: 0;
  background-color: #4da6b0;
  color: white;
  text-align: center;
  padding: 20px 10px;
  margin-top: 30px;
  border-top: 3px solid #c3e8eb;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
}

.footer p {
  margin: 0;
  font-size: 1rem;
}

.footer-links {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
  gap: 15px;
}

.footer-links li {
  display: inline;
}

.footer a {
  color: white;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s;
}

.footer a:hover {
  color: #76c7c0;
}

/* Адаптивність для футера */
@media (max-width: 768px) {
  .footer-links {
    flex-direction: column;
    gap: 10px;
  }

  .footer p {
    font-size: 0.9rem;
  }
}


.app-container{
  min-height: 100vh;
}


