/* .profile-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 20px;
}
  
.profile-card {
    display: flex;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    background-color: #fff;
    width: 100%;
    max-width: 800px;
}
  
.profile-photo {
    margin-right: 20px;
}
  
.profile-photo img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}
  
.upload-button {
    display: inline-block;
    margin-top: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
}
  
.profile-info {
    flex-grow: 1;
}
  
.profile-info h2, .profile-info h4, .profile-info p {
    margin: 5px 0;
} */
  
.edit-form {
    display: flex;
    flex-direction: column;
}
  
.form-group {
    margin-bottom: 10px;
}
  
.form-group label {
    display: block;
    margin-bottom: 5px;
}
  
.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  
.save-button, .cancel-button, .edit-button {
    margin-top: 10px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.save-button {
    background-color: #28a745;
    color: #fff;
}
  
.cancel-button {
    background-color: #dc3545;
    color: #fff;
    margin-left: 10px;
}
  
.edit-button {
    background-color: #007bff;
    color: #fff;
}