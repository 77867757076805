.profile-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    gap: 20px;
}
  
.profile-card {
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    width: 100%; /* Ширина 100% контейнера */
    max-width: 900px; /* Максимальна ширина для картки */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.profile-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    flex-shrink: 0; /* Запобігає зменшенню розміру фото */
}
  
.profile-photo img {
    width: 100%;
    max-width: 250px;
    height: auto;
    max-height: 250px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
}
  
.upload-button {
    display: inline-block;
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.upload-button:hover {
    background-color: #45a049;
}
  
.profile-info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
  
.profile-info h2 {
    margin-top: 0;
    color: #000000; /* Червоний колір заголовка */
}
  
.profile-info h4, .profile-info p {
    margin-bottom: 0.5rem;
    color: #666;
}
  
.profile-details {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    width: 100%; /* Ширина 100% контейнера */
    max-width: 900px; /* Максимальна ширина для картки */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.profile-details h3 {
    margin-top: 0;
    color: #333;
}
  
.profile-details p {
    margin-bottom: 1rem;
    color: #666;
}
  
  /* Додаткові стилі для кращого вигляду на мобільних пристроях */
@media (max-width: 768px) {
    .profile-card, .profile-details {
      flex-direction: column;
      align-items: center;
    }
    
    .profile-photo {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .profile-info {
      text-align: center;
    }
}