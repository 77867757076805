.order-card {
    background-color: #ffffff;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    padding: 20px;
    width: 100%; /* Ширина 100% контейнера */
    max-width: 400px; /* Максимальна ширина для картки */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 10px;
}
  
.order-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
  
.order-info h2 {
    margin-top: 0;
    color: #e74c3c; /* Червоний колір заголовка */
}
  
.order-info p {
    margin: 0;
    color: #666;
}
  
.order-info p strong {
    color: #333;
}

.order-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center; /* Центруємо картки */
    padding: 20px;
}
  
  /* Додаткові стилі для кращого вигляду на мобільних пристроях */
@media (max-width: 768px) {
    .order-card {
      max-width: 100%; /* Знімаємо обмеження на ширину */
    }
}
  