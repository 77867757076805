.group-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f7f7f7;
  }
  
  .group-form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
  }
  
  .group-form .form-group {
    margin-bottom: 15px;
  }
  
  .group-form .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .group-form .form-group input,
  .group-form .form-group select {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .group-form .form-group select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .group-form .form-group img {
    max-width: 100px;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  .group-form button {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .group-form button:hover {
    background-color: #0056b3;
  }