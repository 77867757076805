.home-container {
  font-family: Arial, sans-serif;
  min-height: 100vh;
  /* background-color: #5cb3c3; */
  color: black;
  border-radius: 40px 40px 0 0;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

/* Вітальний блок */
.welcome-section {
  margin: 20px auto;
  background-color: #c3e8eb;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  max-width: 600px;
}

.welcome-section h1 {
  margin-bottom: 15px;
  font-size: 2.2rem;
  font-weight: bold;
}

.welcome-section p {
  font-size: 1.2rem;
  line-height: 1.5;
}

/* Секція карток */
.features-section {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
}

.feature-card {
  background-color: #c3e8eb;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  text-align: left;
  box-sizing: border-box;
}

.feature-card h2 {
  margin-bottom: 10px;
  font-size: 1.6rem;
  font-weight: bold;
}

.feature-card p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Адаптивність */
@media (max-width: 768px) {
  .welcome-section {
    padding: 20px;
  }

  .welcome-section h1 {
    font-size: 1.8rem;
  }

  .welcome-section p {
    font-size: 1rem;
  }

  .features-section {
    flex-direction: column;
    align-items: center;
  }

  .feature-card {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .welcome-section h1 {
    font-size: 1.6rem;
  }

  .welcome-section p {
    font-size: 0.9rem;
  }

  .feature-card h2 {
    font-size: 1.4rem;
  }

  .feature-card p {
    font-size: 0.9rem;
  }
}