.auth-container {
    max-width: 400px;
    margin: 10px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
  
.auth-container h2 {
    text-align: center;
    color: #333;
}
  
.auth-container form {
    display: flex;
    flex-direction: column;
}
  
.auth-container label {
    margin: 10px 0 5px;
    color: #555;
}
  
.auth-container input {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
  
.auth-container button {
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
  
.auth-container button:hover {
    background-color: #0056b3;
}